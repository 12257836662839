import {
  Button,
  Center,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import skills from "../../../assets/skills.png";
import "../../../styles/components/hero.scss";

const Hero = ({ passion, setPassion, setOptions }) => {
  return (
    <>
      <Center mb={"12rem"} flexDirection={"column"} w={"full"} className="hero">
        <VStack
          w={{ base: "100%", lg: "50%" }}
          p={{ base: "0 8px", lg: "unset" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Img w={["80%", "50%"]} mb={10} src={"/images/h.jpg"} /> */}
          <Heading className="hero__heading">
          Unlock Your Potential <br />
            {/* Master Africa: A Fuel Station for the Mind */}
          </Heading>
          <Text className="hero__content">
            {/* Master Africa helps Masters with years of experience to turn
            available spaces such as homes, churches, lecture halls, parks,
            restaurants e.t.c into Master Training Centers (MTC) and connects
            them with Apprentices who find it hard to learn online. Start Your
            Search Today! */}
            {/* Search for Spaces and Creators to Create Content to Grow Your
            Business. Start Your Search Today! */}
            Compete for Apprenticeship Scholarships and Free Training Tools to Boost Your Hiring Chances
            {/* Fuel your growth with our unique blend of: {"\n"}
            <ul>
              <li>Game-based learning</li>
              <li>Interactive live sessions with experts</li>
              <li>Community networking</li>
              <li>In-person training & job opportunities</li>
            </ul> */}
          </Text>
          <Button
            className="hero__action"
            h={"full"}
            onClick={() => window.location.replace("/#form")}
          >
            Play Now
          </Button>
        </VStack>
        <Image
          display={{ base: "none", lg: "block" }}
          m={"71px 0 89px 0"}
          src={skills}
        />
        {/* <Heading className="hero__ending">
          A fun social way to meet Masters, Creative Space Owners {"(MIC’s)"}{" "}
          and Creators.
        </Heading>
      </Center>
      <Box w={"84%"} h={"75vh"} m={"3rem auto 12rem"}>
        <iframe
          style={{
            borderRadius: "10px",
            height: "100%",
            width: "100%",
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/mqHWl7ThGEE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Box> */}
      </Center>
    </>
  );
};

export default Hero;
